<template>
  <App
    left-arrow
    title="订单跟进"
    @click-right="onClickRight"
    class="allSearch"
  >
    <Tabs
      v-model="tabActive"
      slot="extra"
      @change="tableChange"
    >
      <Tab
        :title="$i.title"
        v-for="$i in tabsData"
        :key="$i.label"
        :name="$i.label"
      >
      </Tab>
    </Tabs>
    <PullRefresh
      @refresh="onRefresh"
      v-model="loading"
    >
      <List
        v-model="loading"
        :immediate-check="false"
        :error.sync="error"
        :finished="finished"
        :finished-text="total === 0 ? '暂无数据!' : 'No more items.!'"
        error-text="数据加载失败! 点击重试"
        @load="Onload"
      >
        <CardItem
          v-for="$items in list"
          :key="$items.id"
          :cardInfo="$items"
          :type="tabActive"
        />
      </List>
    </PullRefresh>
  </App>
</template>

<script>
import dayjs from 'dayjs'
import { debounce } from 'lodash-es'
import { Tab, Tabs, PullRefresh, List, Toast } from 'vant'
import { tabsData, OrderStatusEnmu } from './attr.js'
import CardItem from './components/card/index'
import { depositPaidList, handOffList, drRefundList } from '@/api/follow/index'
import '@/style/vant-patch.less'
export default {
  name: 'orderFollowUp',
  data () {
    return {
      showResult: false,
      hasDate: true,
      loading: false,
      finished: false,
      error: false,
      radioVal: '1',
      list: [],
      tabActive: this.$route.query.tabName,
      tabsData: [],
      page: 1,
      size: 10,
      total: 0,
      oppStatus: []
    }
  },
  components: {
    Tab,
    Tabs,
    CardItem,
    List,
    PullRefresh,
  },
  methods: {
    getData: debounce(function () {
      if (this.finished) return
      switch (this.tabActive) {
        case 'largeSet':
          this.getDepositPaidList()
          break;
        case 'handOff':
          this.getHandOffList()
          break;
        case 'intentionGold':
          this.getDrRefundList()
          break;
      }

    }, 600, { leading: true, trailing: false }),
    // 大定已付待跟进
    async getDepositPaidList () {
      try {
        Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          loadingType: 'spinner',
          overlay: true
        })
        const res = await depositPaidList({ 'page': this.page, 'size': this.size, 'currentIdentity': this.$route.query.currentIdentity })
        Toast.clear()
        if (res && res.success) {
          res.data.records.forEach(el => {
            el['createtime'] = dayjs(el['createtime']).format('YYYY/MM/DD HH:mm:ss')
            this.oppStatus.forEach(item => {
              if (el.oppStatus === item.value) {
                el.oppStatus = item.key
              }
            })
          })
          this.total = res.data.total
          this.list = [...this.list, ...res.data.records]
          if (this.list.length === res.data.total) {
            this.finished = true
          } else {
            this.page++
          }
          this.error = false
          this.loading = false
        } else {
          this.error = true
          this.loading = false
          Toast(res.msg)
        }
      } catch (error) {
        Toast.clear()
        console.log(error)
        this.error = true
        this.loading = false
      }
    },
    // hand-over
    async getHandOffList () {
      try {
        Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          loadingType: 'spinner',
          overlay: true
        })
        const res = await handOffList({ 'page': this.page, 'size': this.size, 'currentIdentity': this.$route.query.currentIdentity })
        Toast.clear()
        if (res && res.success) {
          res.data.records.forEach(el => {
            el['createtime'] = dayjs(el['createtime']).format('YYYY/MM/DD HH:mm:ss')
            Object.keys(OrderStatusEnmu).forEach(item => {
              if (el.orderStatus === item) {
                el.orderStatus = OrderStatusEnmu[item]
              }
            })
          })
          this.total = res.data.total
          this.list = [...this.list, ...res.data.records]
          if (this.list.length === res.data.total) {
            this.finished = true
          } else {
            this.page++
          }
          this.error = false
          this.loading = false
        } else {
          this.error = true
          this.loading = false
          Toast(res.msg)
        }
      } catch (error) {
        Toast.clear()
        console.log(error)
        this.error = true
        this.loading = false
      }
    },
    // 意向金退款
    async getDrRefundList () {
      try {
        Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          loadingType: 'spinner',
          overlay: true
        })
        const res = await drRefundList({ 'page': this.page, 'size': this.size, 'currentIdentity': this.$route.query.currentIdentity })
        Toast.clear()
        if (res && res.success) {
          res.data.records.forEach(el => {
            el['createtime'] = dayjs(el['createtime']).format('YYYY/MM/DD HH:mm:ss')
            Object.keys(OrderStatusEnmu).forEach(item => {
              if (el.orderStatus === item) {
                el.orderStatus = OrderStatusEnmu[item]
              }
            })
          })
          this.total = res.data.total
          this.list = [...this.list, ...res.data.records]
          if (this.list.length === res.data.total) {
            this.finished = true
          } else {
            this.page++
          }
          this.error = false
          this.loading = false
        } else {
          this.error = true
          this.loading = false
          Toast(res.msg)
        }
      } catch (error) {
        Toast.clear()
        console.log(error)
        this.error = true
        this.loading = false
      }
    },
    tableChange () {
      this.page = 1
      this.list = []
      this.total = 0
      this.finished = false
      this.loading = false
      this.getData()
    },
    // 下拉刷新
    onRefresh () {
      this.page = 1
      this.finished = false
      this.list = []
      this.total = 0
      this.getData()
    },
    Onload () {
      this.getData()
      this.loading = false
    },
    init () {
      this.tabsData = tabsData
      this.tabActive = this.$route.query.tabName
      this.oppStatus = this.$storage.get('baseData')['opp_status']
      this.getData()
    },
    onClickRight () {
      this.showResult = false
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>